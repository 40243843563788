<template>
  <VRow
    dense
    align="center"
  >
    <VCol
      v-if="!disabled"
      cols="auto"
    >
      <VSheet width="28">
        <VChip
          v-if="showPrefix"
          class="d-flex justify-center"
          label
          data-test="condition-tag-and"
        >
          и
        </VChip>
      </VSheet>
    </VCol>
    <VCol
      cols="auto"
    >
      тег
    </VCol>
    <VCol>
      <TTSelect
        :value="rule"
        :items="rules"
        :disabled="disabled"
        data-test-label="condition-rule-select"
        @input="handleChangeRule"
      />
    </VCol>
    <VCol>
      <TTSelect
        :value="option"
        :items="options"
        :disabled="disabled"
        data-test-label="condition-option-select"
        @input="handleChangeOption"
      />
    </VCol>
    <VCol
      v-if="!disabled"
      cols="auto"
    >
      <VSheet min-width="36">
        <TTBtn
          v-if="showDeleteButton"
          icon
          color="tt-danger"
          data-test-label="condition-button-delete"
          @click="handleClickDeleteCondition"
        >
          <VIcon>
            fal fa-trash-alt
          </VIcon>
        </TTBtn>
      </VSheet>
    </VCol>
    <VCol
      v-if="!disabled"
      cols="auto"
    >
      <VSheet min-width="36">
        <TTBtn
          v-show="showCreateButton"
          icon
          data-test-label="condition-button-create"
          @click="handleClickCreateCondition"
        >
          <VIcon> fal fa-plus</VIcon>
        </TTBtn>
      </VSheet>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'Condition',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showPrefix: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    rule: {
      type: String,
      default: '',
    },
    option: {
      type: [String, Object],
      default: '',
    },
    rules: {
      type: [Array, Object],
      default: () => ([
        {
          text: 'является',
          value: 'is',
        },
        {
          text: 'не является',
          value: 'not',
        },
      ]),
    },
    options: {
      type: [Array],
      default: () => ([
        {
          text: 'Дизайнер',
          value: 'designer',
        },
        {
          text: 'Разработчик',
          value: 'developer',
        }]),
    },
  },
  methods: {
    handleChangeRule(value) {
      this.$emit('update:rule', value);
    },
    handleChangeOption(value) {
      this.$emit('update:option', value);
    },
    handleClickDeleteCondition() {
      this.$emit('click:condition-delete');
    },
    handleClickCreateCondition() {
      this.$emit('click:condition-create');
    },
  },
};
</script>

<style scoped>

</style>
