<template>
  <VContainer class="px-0">
    <template v-for="(group,groupIndex) in tree">
      <VRow
        v-if="group.children.length"
        :key="groupIndex"
      >
        <VCol>
          <VRow>
            <VCol>
              <CardWrapper
                min-height="auto"
                shadow="never"
                data-test="condition-group"
                class="border--dashed"
                :class="{'border--disabled' : disabled}"
              >
                <template v-for="(children,index) in group.children">
                  <Condition
                    :key="`condition-key-${index}`"
                    :data-test="`condition-${index}`"
                    :show-prefix="index>0"
                    :disabled="disabled"
                    :show-create-button="index+1===group.children.length"
                    :rule.sync="children.rule"
                    :option.sync="children.option"
                    @click:condition-delete="handleClickDeleteCondition({groupIndex,conditionIndex : index})"
                    @click:condition-create="handleClickCreateCondition({groupIndex})"
                  />
                </template>
              </CardWrapper>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
      <VRow
        v-if="groupIndex+1!==tree.length"
        :key="`tag-or-${groupIndex}`"
      >
        <VCol>
          <VChip
            label
            class="ml-6"
            data-test="condition-tag-or"
          >
            или
          </VChip>
        </VCol>
      </VRow>
    </template>
    <VRow v-if="showNewGroupButton">
      <VCol>
        <TTBtn
          text
          class="px-0"
          data-test-label="conidtion-button-group-create"
          @click="handleClickNewGroupCondition"
        >
          <VSheet
            height="28"
            width="28"
            color="tt-light-blue"
            class="mr-3"
            rounded
          >
            <VRow
              class="fill-height"
              no-gutters
              align="center"
              justify="center"
            >
              <VCol cols="auto">
                <VIcon color="tt-white">
                  fal fa-plus
                </VIcon>
              </VCol>
            </VRow>
          </VSheet>
          {{ addNewConditionButtonText }}
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>

import CardWrapper from '@/components/shared/CardWrapper.vue';
import Condition from '@/components/consturctor/Condition.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ConditionConstructor',
  components: {
    Condition,
    CardWrapper,
  },
  inject: ['Names'],
  props: {
    treeInit: {
      type: [Array, Object],
      default: () => ([]),
    },
    rules: {
      type: [Array, Object],
      default: () => ([
        {
          text: 'является',
          value: 'is',
        },
        {
          text: 'не является',
          value: 'not',
        },
      ]),
    },
    options: {
      type: [Array],
      default: () => ([
        {
          text: 'Дизайнер',
          value: 'designer',
        },
        {
          text: 'Разработчик',
          value: 'developer',
        }]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showNewGroupButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tree: [],
    };
  },
  computed: {
    addNewConditionButtonText() {
      return this.tree.length > 0 ? 'Добавить условие «или»' : 'Добавить условие автодобавления';
    },
    isGroupIdPage() {
      return this.$route.name === this.Names.R_GROUP;
    },
  },
  watch: {
    tree: {
      deep: true,
      handler(value) {
        this.$emit('update:tree-init', value);
      },
    },
  },
  mounted() {
    this.tree = cloneDeep(this.treeInit);
  },
  methods: {
    handleClickNewGroupCondition() {
      if (this.isGroupIdPage) {
        this.$router.push({
          name: this.Names.R_GROUP_MANAGEMENT,
          params: { id: this.$route.params.id },
          hash: '#conditions',
        });
      } else {
        this.$emit('click:group-create');
      }
    },
    handleClickDeleteCondition({
      groupIndex,
      conditionIndex,
    }) {
      this.$emit('click:condition-remove', {
        groupIndex,
        conditionIndex,
      });
    },
    handleClickCreateCondition({ groupIndex }) {
      this.$emit('click:condition-create', { groupIndex });
    },
  },
};
</script>
